@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
		font-family: 'Questrial', 'sans-serif';
	}
}

body {
	touch-action: pan-x pan-y;
}

.react-calendar__tile--active {
	@apply bg-blue-500 rounded-xl text-white;
}
